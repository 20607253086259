var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',[_c('script',{attrs:{"type":"application/javascript","defer":""}},[_vm._v(" window.mParticle.logPageView(\"Live Page\", { page: window.location.toString(), }); ")])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"gridwrapper"},[_c('h2',{staticClass:"m-0 text-left"},[_vm._v("Shop Livestreams ")]),_c('div',{staticClass:"d-flex align-items-end"},[_c('h5',{staticClass:"m-0 text-left featured-heading d-none d-md-block"},[_vm._v("Featured ")])]),_c('div',{staticClass:"video-container"},[_c('video',{staticClass:"video-elem",attrs:{"id":"video-player","playsInline":"","muted":""},domProps:{"muted":true}})]),_c('div',{staticClass:"position-relative featured-product-column d-none d-md-block"},[_c('div',{staticClass:"featured-products"},_vm._l((_vm.productDetails[_vm.activeStreamId]),function(product){return _c('router-link',{key:("featured-prod-" + (product.id)),staticClass:"featured-product pt-1 pb-2 px-2 d-flex flex-column justify-content-between mb-2 mr-1",class:{'active-product': product.id === _vm.activeProductId.toString()},attrs:{"to":{
                name: 'ProductDetail',
                params: { id: product.id },
                query: { exp: _vm.productActiveExperiment, feature: _vm.feature, di: product.discounted},
              }}},[_c('div',[_c('img',{staticClass:"mb-2 img-fluid w-100",attrs:{"src":product.image,"alt":""}}),_c('div',{staticClass:"product-name"},[_vm._v(" "+_vm._s(product.name)+" ")]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col",class:{'discounted': product.discounted}},[_vm._v(_vm._s(_vm.formatPrice(product.price)))]),(product.discounted)?_c('div',{staticClass:"col font-weight-bold"},[_vm._v(_vm._s(_vm.formatPrice(_vm.discountProductPrice(product.price))))]):_vm._e()])])])])}),1)]),_c('div',{staticClass:"stream-selector"},[_c('div',{staticClass:"row"},_vm._l((_vm.streamDetails),function(_,index){return _c('div',{key:("stream-" + index),staticClass:"col-3",class:{'d-none': index === _vm.activeStreamId},attrs:{"id":("thumb-" + index)}},[_c('div',{staticClass:"thumb-wrapper",on:{"click":function($event){_vm.activeStreamId = index; _vm.activeProductId = 0;}}},[_c('div',{staticClass:"thumb-overlay"}),_c('img',{staticClass:"channel-thumb",attrs:{"src":_vm.streamDetails[index]['thumb_url']}})])])}),0)])]),_c('div',{staticClass:"container d-md-none"},[_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col pl-0 text-left"},[_c('h3',[_vm._v("Featured")])])]),_c('div',{staticClass:"row"},_vm._l((_vm.productDetails[_vm.activeStreamId]),function(product){return _c('div',{key:("featured-prod-" + (product.id)),staticClass:"col-6 p-0"},[_c('router-link',{staticClass:"featured-product pt-1 pb-2 px-2 d-flex flex-column justify-content-between mb-2 mr-1",class:{'active-product': product.id === _vm.activeProductId.toString()},attrs:{"to":{
                  name: 'ProductDetail',
                  params: { id: product.id },
                  query: { exp: _vm.productActiveExperiment, feature: _vm.feature},
                }}},[_c('div',[_c('img',{staticClass:"mb-2 img-fluid w-100",attrs:{"src":product.image,"alt":""}}),_c('div',{staticClass:"product-name"},[_vm._v(" "+_vm._s(product.name)+" ")]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col",class:{'discounted': product.discounted}},[_vm._v(_vm._s(_vm.formatPrice(product.price)))]),(product.discounted)?_c('div',{staticClass:"col font-weight-bold"},[_vm._v(_vm._s(_vm.formatPrice(_vm.discountProductPrice(product.price))))]):_vm._e()])])])])],1)}),0)]),_c('RecommendedProductsSection',{key:("rec-products-" + ((new Date()).getTime())),staticClass:"mt-5",attrs:{"experiment":_vm.experiment,"recommendedProducts":_vm.productRecommended,"feature":_vm.feature},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("Compare similar items "),(_vm.demoGuideBadgeArticle)?_c('DemoGuideBadge',{attrs:{"article":_vm.demoGuideBadgeArticle,"hideTextOnSmallScreens":""}}):_vm._e()]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <svg viewBox="0 0 500 105" class="icon">
    <g>
      <path
        d="M393.1,43.3c0,1.7,0.2,3,0.5,4c0.3,1,0.8,2.1,1.5,3.2c0.2,0.4,0.3,0.7,0.3,1c0,0.5-0.3,0.9-0.9,1.3
							l-2.8,1.9c-0.4,0.3-0.8,0.4-1.2,0.4c-0.5,0-0.9-0.2-1.3-0.6c-0.6-0.6-1.2-1.3-1.6-2.1c-0.4-0.8-0.9-1.6-1.4-2.6
							c-3.5,4.1-7.9,6.2-13.2,6.2c-3.8,0-6.7-1.1-8.9-3.2c-2.2-2.1-3.3-5-3.3-8.6c0-3.8,1.4-6.9,4.1-9.2c2.7-2.3,6.4-3.5,11-3.5
							c1.5,0,3.1,0.1,4.7,0.4c1.6,0.2,3.4,0.6,5.1,1v-3.3c0-3.4-0.7-5.8-2.1-7.2c-1.4-1.4-3.8-2.1-7.3-2.1c-1.6,0-3.2,0.2-4.8,0.6
							c-1.6,0.4-3.2,0.9-4.8,1.5c-0.7,0.3-1.3,0.5-1.6,0.6c-0.3,0.1-0.5,0.1-0.7,0.1c-0.6,0-0.9-0.5-0.9-1.4v-2.2
							c0-0.7,0.1-1.3,0.3-1.6c0.2-0.3,0.6-0.6,1.3-0.9c1.6-0.8,3.4-1.5,5.6-2c2.2-0.6,4.5-0.9,7-0.9c5.3,0,9.2,1.2,11.7,3.6
							s3.7,6.1,3.7,11V43.3 M374.9,50.1c1.5,0,3-0.3,4.6-0.8c1.6-0.5,3-1.5,4.2-2.9c0.7-0.8,1.2-1.8,1.5-2.9
							c0.3-1.1,0.4-2.4,0.4-3.9v-1.9c-1.3-0.3-2.7-0.6-4.1-0.7c-1.4-0.2-2.8-0.3-4.2-0.3c-3,0-5.2,0.6-6.7,1.8
							c-1.5,1.2-2.2,2.9-2.2,5.2c0,2.1,0.5,3.7,1.6,4.7C371.3,49.6,372.9,50.1,374.9,50.1 M410.9,55c-0.8,0-1.4-0.1-1.7-0.4
							c-0.4-0.3-0.7-0.9-0.9-1.8l-10.5-34.6c-0.3-0.9-0.4-1.5-0.4-1.8c0-0.7,0.4-1.1,1.1-1.1h4.4c0.8,0,1.4,0.1,1.8,0.4
							c0.3,0.3,0.6,0.9,0.9,1.8l7.5,29.6l7-29.6c0.2-0.9,0.5-1.5,0.9-1.8c0.4-0.3,1-0.4,1.8-0.4h3.6c0.8,0,1.4,0.1,1.8,0.4
							c0.4,0.3,0.7,0.9,0.9,1.8l7,30l7.8-30c0.3-0.9,0.6-1.5,0.9-1.8c0.3-0.3,0.9-0.4,1.8-0.4h4.2c0.7,0,1.1,0.4,1.1,1.1
							c0,0.2,0,0.4-0.1,0.7c-0.1,0.3-0.2,0.6-0.3,1.1l-10.8,34.6c-0.3,0.9-0.6,1.5-0.9,1.8c-0.4,0.3-0.9,0.4-1.7,0.4h-3.8
							c-0.8,0-1.4-0.2-1.8-0.5c-0.4-0.3-0.7-0.9-0.9-1.8l-7-28.8l-6.9,28.8c-0.2,0.9-0.5,1.5-0.9,1.8c-0.4,0.3-1,0.5-1.8,0.5H410.9z
              M468.4,56.2c-2.4,0-4.6-0.3-6.9-0.8c-2.2-0.5-4-1.1-5.2-1.8c-0.7-0.4-1.2-0.8-1.4-1.2c-0.2-0.4-0.3-0.8-0.3-1.3v-2.3
							c0-0.9,0.3-1.4,1-1.4c0.3,0,0.5,0.1,0.8,0.2c0.3,0.1,0.7,0.3,1.1,0.5c1.5,0.7,3.2,1.2,4.9,1.6c1.8,0.4,3.5,0.5,5.3,0.5
							c2.8,0,5-0.5,6.5-1.5c1.5-1,2.3-2.4,2.3-4.2c0-1.3-0.4-2.3-1.2-3.1c-0.8-0.8-2.3-1.6-4.5-2.4l-6.5-2c-3.3-1-5.7-2.6-7.2-4.5
							c-1.5-2-2.2-4.1-2.2-6.5c0-1.9,0.4-3.5,1.2-5c0.8-1.4,1.9-2.7,3.2-3.7c1.3-1,2.9-1.8,4.7-2.3c1.8-0.5,3.7-0.8,5.6-0.8
							c1,0,2,0.1,3,0.2c1,0.1,2,0.3,2.9,0.5c0.9,0.2,1.8,0.4,2.5,0.7c0.8,0.3,1.4,0.5,1.9,0.8c0.6,0.4,1.1,0.7,1.3,1.1
							c0.3,0.4,0.4,0.9,0.4,1.5V21c0,0.9-0.3,1.4-1,1.4c-0.4,0-0.9-0.2-1.7-0.5c-2.6-1.2-5.4-1.7-8.6-1.7c-2.6,0-4.5,0.4-6,1.3
							c-1.4,0.8-2.1,2.2-2.1,4c0,1.2,0.4,2.3,1.3,3.2c0.9,0.9,2.5,1.7,4.9,2.5l6.3,2c3.2,1,5.5,2.5,6.9,4.3c1.4,1.8,2.1,3.9,2.1,6.3
							c0,1.9-0.4,3.7-1.2,5.2c-0.8,1.5-1.9,2.9-3.3,4c-1.4,1.1-3,1.9-5,2.5C472.8,55.9,470.7,56.2,468.4,56.2"
      />
      <path
        class="st1"
        d="M476.8,78.2c-14.7,10.9-36.1,16.7-54.5,16.7c-25.8,0-49-9.5-66.6-25.4c-1.4-1.2-0.1-2.9,1.5-2
							c19,11,42.4,17.7,66.6,17.7c16.3,0,34.3-3.4,50.8-10.4C477.2,73.7,479.3,76.4,476.8,78.2"
      />
      <path
        class="st1"
        d="M483,71.2c-1.9-2.4-12.5-1.1-17.2-0.6c-1.4,0.2-1.7-1.1-0.4-2c8.4-5.9,22.3-4.2,23.9-2.2
							c1.6,2-0.4,15.9-8.3,22.5c-1.2,1-2.4,0.5-1.8-0.9C480.9,83.6,484.8,73.6,483,71.2"
      />
    </g>
    <g>
      <path
        d="M11.1,68c-0.9,0-1.3-0.4-1.3-1.3V25.6c0-0.9,0.4-1.3,1.3-1.3h3.4c0.9,0,1.4,0.4,1.6,1.2l0.4,1.9
					c1.4-1.3,3-2.3,4.7-3c1.7-0.7,3.5-1,5.4-1c3.9,0,7,1.4,9.3,4.3c2.3,2.9,3.5,6.8,3.5,11.7c0,3.4-0.6,6.3-1.7,8.9
					c-1.1,2.5-2.7,4.5-4.7,5.8c-2,1.3-4.3,2-6.9,2c-1.7,0-3.4-0.3-5-0.9c-1.6-0.6-3-1.4-4.2-2.5v13.9c0,0.9-0.4,1.3-1.3,1.3H11.1z
					M24.3,50.4c2.6,0,4.5-0.9,5.8-2.7c1.3-1.8,1.9-4.5,1.9-8.1c0-3.6-0.6-6.3-1.9-8c-1.2-1.7-3.2-2.6-5.8-2.6
					c-2.7,0-5.1,0.8-7.3,2.3V48C19.3,49.6,21.7,50.4,24.3,50.4z"
      />
      <path
        d="M60,56.3c-4.7,0-8.4-1.5-11.1-4.4c-2.7-2.9-4-7-4-12.1c0-5.2,1.3-9.2,4-12.1c2.7-2.9,6.3-4.3,11.1-4.3
					c4.7,0,8.4,1.4,11.1,4.3c2.7,2.9,4,6.9,4,12.1c0,5.2-1.3,9.2-4,12.1S64.7,56.3,60,56.3z M60,50.6c5.1,0,7.7-3.6,7.7-10.8
					c0-7.2-2.6-10.8-7.7-10.8c-5.1,0-7.7,3.6-7.7,10.8C52.3,47,54.9,50.6,60,50.6z"
      />
      <path
        d="M89.2,55.4c-0.9,0-1.5-0.4-1.8-1.3l-9.1-27.8c-0.2-0.6-0.3-1-0.3-1.2c0-0.6,0.4-0.9,1.2-0.9h5
					c0.9,0,1.4,0.4,1.6,1.2L91.6,48l5.9-22.5c0.1-0.5,0.3-0.8,0.6-1c0.3-0.2,0.6-0.2,1.1-0.2h4c0.5,0,0.9,0.1,1.2,0.3
					c0.2,0.2,0.4,0.5,0.6,0.9l5.9,22.9l6.1-23c0.2-0.8,0.7-1.2,1.6-1.2h4.5c0.8,0,1.2,0.3,1.2,0.9c0,0.2-0.1,0.6-0.3,1.2l-9.2,27.8
					c-0.3,0.9-0.9,1.3-1.8,1.3h-4.3c-0.5,0-0.9-0.1-1.2-0.3c-0.3-0.2-0.5-0.5-0.6-1L101,32l-5.8,22.1c-0.1,0.5-0.3,0.8-0.6,1
					c-0.3,0.2-0.7,0.3-1.2,0.3H89.2z"
      />
      <path
        d="M142.9,56.3c-5.1,0-9-1.4-11.7-4.2c-2.7-2.8-4-6.8-4-12.1c0-5.3,1.3-9.4,3.9-12.3c2.6-2.9,6.2-4.4,10.9-4.4
					c4,0,7.1,1.1,9.2,3.3c2.1,2.2,3.2,5.3,3.2,9.3c0,1.4-0.1,2.8-0.3,4.3c-0.1,0.5-0.2,0.9-0.5,1.1c-0.2,0.2-0.5,0.3-0.9,0.3h-18.8
					c0.1,3.1,1,5.4,2.6,6.8c1.6,1.4,4.1,2.2,7.4,2.2c2.4,0,5-0.4,7.7-1.2c0.3-0.1,0.6-0.1,0.7-0.2c0.2,0,0.3-0.1,0.4-0.1
					c0.6,0,0.9,0.4,0.9,1.2v2.2c0,0.6-0.1,1.1-0.3,1.3c-0.2,0.3-0.6,0.5-1.3,0.8C149.5,55.8,146.4,56.3,142.9,56.3z M148,36.8v-0.5
					c0-2.5-0.5-4.4-1.6-5.7c-1.1-1.3-2.7-2-4.7-2c-2.2,0-4,0.7-5.4,2.2c-1.4,1.5-2.2,3.4-2.4,5.9H148z"
      />
      <path
        d="M162.8,55.4c-0.9,0-1.3-0.4-1.3-1.3V25.6c0-0.9,0.4-1.3,1.3-1.3h3.2c0.6,0,1,0.1,1.2,0.2
					c0.2,0.2,0.4,0.5,0.5,0.9l0.7,3.5c1.6-1.8,3.2-3.1,4.6-3.8c1.4-0.7,3-1.1,4.7-1.1h0.2c0.5,0,1,0.1,1.3,0.2
					c0.3,0.1,0.6,0.3,0.7,0.5c0.1,0.2,0.2,0.7,0.2,1.3v3.3c0,0.9-0.4,1.3-1.1,1.3c-0.2,0-0.6,0-1-0.1c-0.4,0-0.9-0.1-1.4-0.1
					c-3,0-5.7,0.8-8,2.3v21.2c0,0.9-0.4,1.3-1.3,1.3H162.8z"
      />
      <path
        d="M199.1,56.3c-5.1,0-9-1.4-11.7-4.2c-2.7-2.8-4-6.8-4-12.1c0-5.3,1.3-9.4,3.9-12.3c2.6-2.9,6.2-4.4,10.9-4.4
					c4,0,7.1,1.1,9.2,3.3c2.1,2.2,3.2,5.3,3.2,9.3c0,1.4-0.1,2.8-0.3,4.3c-0.1,0.5-0.2,0.9-0.5,1.1c-0.2,0.2-0.5,0.3-0.9,0.3h-18.8
					c0.1,3.1,1,5.4,2.6,6.8c1.6,1.4,4.1,2.2,7.4,2.2c2.4,0,5-0.4,7.7-1.2c0.3-0.1,0.6-0.1,0.7-0.2c0.2,0,0.3-0.1,0.4-0.1
					c0.6,0,0.9,0.4,0.9,1.2v2.2c0,0.6-0.1,1.1-0.3,1.3c-0.2,0.3-0.6,0.5-1.3,0.8C205.7,55.8,202.6,56.3,199.1,56.3z M204.3,36.8
					v-0.5c0-2.5-0.5-4.4-1.6-5.7c-1.1-1.3-2.7-2-4.7-2c-2.2,0-4,0.7-5.4,2.2c-1.4,1.5-2.2,3.4-2.4,5.9H204.3z"
      />
      <path
        d="M229,56.1c-3.9,0-7-1.5-9.4-4.4c-2.3-2.9-3.5-6.8-3.5-11.8c0-3.4,0.6-6.3,1.7-8.8c1.1-2.5,2.7-4.4,4.7-5.8
					c2-1.3,4.3-2,6.9-2c3.3,0,6.2,1.1,8.9,3.2V11.5c0-0.9,0.4-1.3,1.3-1.3h4.5c0.9,0,1.3,0.4,1.3,1.3v42.6c0,0.9-0.4,1.3-1.3,1.3
					h-3.5c-0.9,0-1.4-0.4-1.6-1.2l-0.4-1.8C235.8,54.9,232.6,56.1,229,56.1z M231.2,50.4c2.4,0,4.8-0.8,7-2.3V31.3
					c-2.1-1.5-4.4-2.2-6.9-2.2c-2.7,0-4.7,0.9-6,2.6c-1.3,1.7-1.9,4.4-1.9,8C223.4,46.8,226,50.4,231.2,50.4z"
      />
      <path
        d="M286.7,56.1c-1.8,0-3.6-0.3-5.3-1c-1.7-0.7-3.1-1.6-4.3-2.8l-0.4,2c-0.2,0.8-0.7,1.2-1.6,1.2h-3.4
					c-0.9,0-1.3-0.4-1.3-1.3V11.5c0-0.9,0.4-1.3,1.3-1.3h4.5c0.9,0,1.3,0.4,1.3,1.3V27c2.9-2.4,6.1-3.7,9.6-3.7c3.9,0,7,1.4,9.3,4.3
					c2.3,2.9,3.5,6.8,3.5,11.7c0,3.4-0.6,6.3-1.7,8.9c-1.1,2.5-2.7,4.5-4.7,5.8C291.6,55.4,289.3,56.1,286.7,56.1z M284.8,50.4
					c2.6,0,4.5-0.9,5.8-2.7c1.3-1.8,1.9-4.5,1.9-8.1c0-3.6-0.6-6.3-1.9-8c-1.2-1.7-3.2-2.6-5.8-2.6c-2.7,0-5.1,0.8-7.3,2.3V48
					C279.8,49.6,282.3,50.4,284.8,50.4z"
      />
      <path
        d="M309.5,68.8c-1.5,0-2.8-0.2-3.9-0.5c-0.5-0.2-0.9-0.4-1-0.6c-0.2-0.3-0.2-0.7-0.2-1.3v-2.1
					c0-0.8,0.3-1.2,1-1.2c0.2,0,0.4,0,0.7,0.1c0.3,0,0.8,0.1,1.4,0.1c1.8,0,3.1-0.3,3.9-1c0.9-0.6,1.7-2.1,2.5-4.3l0.8-2.2
					l-11.6-29.6c-0.2-0.4-0.2-0.7-0.2-1c0-0.6,0.4-0.9,1.1-0.9h5c0.9,0,1.4,0.4,1.6,1.2l7.6,23.5l7.7-23.5c0.2-0.8,0.7-1.2,1.6-1.2
					h4.6c0.7,0,1.1,0.3,1.1,0.9c0,0.2-0.1,0.6-0.2,1l-13.3,34.2c-1.1,3-2.5,5.1-4,6.4C314,68.1,312,68.8,309.5,68.8z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PoweredByAWS',
};
</script>

<style scoped>
.icon {
  fill: #252f3e;
}

.st1 {
  fill: #ff9900;
}
</style>

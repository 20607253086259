<template>
  <div class="terms-and-conditions">
    <p class="mb-1">
      The Retail Demo Store is a demo application. All content displayed in the Retail Demo Store is fictitious and for
      demonstration purposes only.
    </p>

    <p class="mb-1">
      All images shown are from <a href="https://unsplash.com" target="_blank" rel="noopener noreferrer">Unsplash</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',
};
</script>

<style scoped>
.terms-and-conditions {
  font-size: 0.85rem;
}
</style>

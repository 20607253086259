<template>
  <svg x="0px" y="0px" viewBox="0 0 245.3 233.2" class="icon">
    <g>
      <path
        d="M149,10H26.6c-4.9,0-8.9,4-8.9,9v79.9c0,4.9,4,9,8.9,9h16.3l0.3,0.5v23c0.3,2.6,1.9,4.8,4.3,5.8
	c0.8,0.3,1.6,0.5,2.4,0.5c1.6,0,3.2-0.6,4.6-1.8l22.7-22.1v-12c0,0,0,0-0.1,0.1l-25.4,24.9v-18c0.2-2.4-0.7-4.7-2.3-6.5
	c-1.7-1.8-4-2.8-6.5-2.8H26.6c-0.2,0-0.4-0.2-0.4-0.5V19c0-0.3,0.2-0.5,0.4-0.5H149c0.2,0,0.4,0.2,0.4,0.5v67.6h8.5V19
	C158,14,154,10,149,10z"
      />
      <rect x="38.9" y="31.3" width="72.3" height="8.5" />
      <rect x="119.7" y="31.3" width="17" height="8.5" />
      <rect x="38.9" y="52.5" width="38.3" height="8.5" />
      <rect x="89.9" y="52.5" width="46.8" height="8.5" />
      <rect x="38.9" y="73.8" width="59.5" height="8.5" />
      <rect x="106.9" y="73.8" width="17" height="8.5" />
      <path
        d="M189.8,222.6c-1.6,0-3.2-0.6-4.4-1.8l-28.6-28c-0.1,0-0.2,0-0.3,0h-62c-5.1-0.4-8.8-4.5-8.8-9.2v-81.8
	c0-6.1,4.9-11,10.8-11h125.4c4.7,0,8.4,4,8.4,9.2v83.9c0,5-3.9,9-8.8,9h-24.9c-0.1,0-0.3,0.2-0.4,0.6V216c0.1,2.6-1.4,5-3.8,6.1
	C191.5,222.4,190.7,222.6,189.8,222.6z"
      />
      <path
        class="background"
        d="M157,184.4c1.9,0,3.8,0.7,5.4,2.1l25.4,24.8v-17.9c0.1-5,4-9,8.8-9h24.9c0.1,0,0.3-0.2,0.3-0.6V100
	c0-0.5-0.4-0.7-0.7-0.7L96,99.3c-1.3,0-1.9,0.7-1.9,2.1v82c0,0.4,0.7,1,1,1C95.2,184.4,156.7,184.4,157,184.4z"
      />
      <rect x="138.8" y="114.2" width="42.5" height="8.5" />
      <rect x="189.9" y="114.2" width="21.3" height="8.5" />
      <rect x="109.1" y="114.2" width="21.3" height="8.5" />
      <rect x="109.1" y="135.4" width="34" height="8.5" />
      <rect x="181.4" y="135.4" width="29.8" height="8.5" />
      <rect x="147.3" y="135.4" width="29.8" height="8.5" />
      <rect x="138.8" y="156.7" width="72.3" height="8.5" />
      <rect x="109.1" y="156.7" width="25.5" height="8.5" />
    </g>
  </svg>
</template>

<script>
export default { name: 'SupportIcon' };
</script>

<style scoped>
.icon {
  fill: var(--grey-600);
}

.background {
  fill: var(--white);
}
</style>
